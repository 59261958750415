import React, { Component } from 'react'
import {base} from '../../base';
import '../../styles/css/main.css';
import { Modal } from 'reactstrap';
const QRCode = require('qrcode.react');


class GameQrHub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            zoomOnItemModal: false,
            itemToZoom: {}
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount(){
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.activeSquareCodesRef = base.bindToState(`activeSquareCodes`, {
            context: this,
            state: 'activeSquareCodes',
            asArray: true
        });
        this.squareItemsListRef = base.bindToState(`squareItemsList`, {
            context: this,
            state: 'squareItemsList',
            asArray: true
        });
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
                this.setState({loading:false})
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.activeSquareCodesRef);
        base.removeBinding(this.squareItemsListRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.tenantVariablesRef);
    }

    zoomOnItem(item){
        this.setState({
            zoomOnItemModal: true,
            itemToZoom: item
        })
    }

    toggle() {
        this.setState({
            zoomOnItemModal: !this.state.zoomOnItemModal,
        });
    }

    downloadQrCode(item){
        const canvas = document.getElementById(item.squareId);
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${item.squareName || item.name}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        let selectedGame = this.state.currentGame || {};
        const customFgColor = tenantVariables.customFgColor || "#000";
        const customBgColor = tenantVariables.customBgColor || "#fff"
        const activeSquareCodes = this.state.activeSquareCodes || [];
        const actualSquares = this.state.squareItemsList || [];
        for(const activeSquareIndex in activeSquareCodes){
            for(const actualSquareIndex in actualSquares){
                if(actualSquares[actualSquareIndex].id === activeSquareCodes[activeSquareIndex].squareId){
                    activeSquareCodes[activeSquareIndex].image = actualSquares[actualSquareIndex].image;
                    activeSquareCodes[activeSquareIndex].name = actualSquares[actualSquareIndex].squareName;
                }
            }
        }

        return (
            <div className="admin-wrapper" id="activeSquaresForClient">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
                <h1>{selectedGame.gameName}</h1>
                <div style={{display:'flex', justifyContent:"center"}}>
                    <button style={{minWidth:200,margin:10}} className="btn btn-primary" onClick={()=>window.print()}>Print</button>
                </div>
                {
                    activeSquareCodes.length > 0 ? (
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            maxWidth: 768,
                            margin: "auto"
                        }}>
                            {
                                activeSquareCodes.map(function(item, index){
                                    return (
                                        <div key={index} style={{width:"26%", maxWidth:"33%",flexGrow:1, marginLeft:5,marginRight:5, textAlign:"center"}}>
                                            <h4>{item.name}</h4>
                                            <img onClick={()=>this.zoomOnItem(item)} width="100%" src={item.image} alt=""/>
                                            {item.codeUrl || item.code ?
                                                <div style={{width:"100%",display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                                    <QRCode size={512} level={'H'} onClick={()=>this.zoomOnItem(item)} id={item.squareId} style={{marginTop:10, height: "auto",maxWidth:128}} value={item.codeUrl || item.code} fgColor={customFgColor} bgColor={customBgColor}/>
                                                    <button style={{color:'black', fontSize: 30, marginLeft: 10, backgroundColor: 'transparent', border:'none'}} className="fa fa-download" onClick={()=> this.downloadQrCode(item)}/>
                                                </div>
                                                :
                                                <p>
                                                    Something Went Wrong and the code wasn't copied over correctly, please recreate the game and try again
                                                </p>
                                            }
                                        </div>
                                    )
                                }, this)
                            }

                        </div>
                    ) : (
                        <div style={{textAlign:'center'}}>
                            <h4>No items active for this current game</h4>
                        </div>
                    )
                }
                <Modal isOpen={this.state.zoomOnItemModal} toggle={this.toggle} id="myModal" style={{textAlign:'center', padding:10, width:"70%"}}>
                    <h4>{this.state.itemToZoom.name}</h4>
                    <div>
                        <img width="150px" style={{margin:"auto"}} src={this.state.itemToZoom.image} alt=""/>
                    </div>
                    <div style={{width:"100%",display: 'flex', alignItems: 'center', justifyContent:'center', marginBottom: 20, marginTop: 10}}>
                        <QRCode size={512} level={'H'} style={{marginTop:10,width:250,height:"auto"}} onClick={()=>this.zoomOnItem(this.state.itemToZoom)} id={this.state.itemToZoom.squareId} value={this.state.itemToZoom.codeUrl || this.state.itemToZoom.code} fgColor={customFgColor} bgColor={customBgColor}/>
                        <button style={{color:'black', fontSize: 30, marginLeft: 10, backgroundColor: 'transparent', border:'none'}} className="fa fa-download" onClick={()=> this.downloadQrCode(this.state.itemToZoom)}/>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default GameQrHub
