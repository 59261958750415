import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import Dropzone from "react-dropzone";
import UploadImage from "../utils/UploadImage";
import {Accordion, AccordionItem} from 'react-sanfona';
import DatePicker from '../utils/DatePicker';
import {organizeUserAnswers} from 'myshared';
import {cleanCSVText, validUrl, convertTimeStampToHumanReadable, findFirstAndLastName, valueDoesExist, getSnapshotFromEndpoint} from '../utils/HelpfulFunction';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            rewardsAdded: [],
            tenantVariables: {},
            emailsSent: {},
            codes: false,
            rewardToAdd: '',
            rewardAmount: 0,
            codesArray: '',
            linksArray: '',
            qrCodes: false,
            activeSquareCodes: {},
            emailVariables: {},
            tenantRules: {},
            id_key: "",
            addedItems:[],
            currentGame: null,
            modal: false,
            userGameHistory: [],
            users: [],
            gameName: "",
            loading: true,
            wizardLocation: 'first',
            endTime: null,
            startTime: null,
            tierMessaging: [],
            deleteAddedItems: false,
            showStats: true,
            responseSize: 100,
            page: 1,
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveReward = this.saveReward.bind(this);
    }

    getCurrentTime(datePassedIn){
        if(!datePassedIn){
            datePassedIn = new Date();
        }
        return `${datePassedIn.getFullYear()}-${`${datePassedIn.getMonth() + 1}`.padStart(2, 0)}-${`${datePassedIn.getDate()}`.padStart(2, 0)}T${`${datePassedIn.getHours()}`.padStart(2, 0)}:${`${datePassedIn.getMinutes()}`.padStart(2, 0)}`;
    }

    componentDidMount() {
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.idKeyRef = base.fetch(`id_key`, {
            context: this,
            then(data){
              this.setState({
                id_key: data
              })
            }
        });
        this.emailsSentCountRef = appDatabasePrimaryFunctions.ref("emailsSent").on('value', function(snapshot) {
            vm.setState({
                emailsSentCount: snapshot.numChildren()
            })
        })
        this.emailVariablesRef = base.bindToState(`emailVariables`, {
            context: this,
            state: 'emailVariables',
        });
        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });
        this.rewardsListRef = base.syncState(`rewardsList`, {
            context: this,
            state: 'ticketList',
            asArray: true
        });
        this.squareItemsListRef = base.bindToState(`squareItemsList`, {
            context: this,
            state: 'squareItemsList',
            asArray: true
        });
        const vm = this;
        this.userSquaresRef = appDatabasePrimaryFunctions.ref("userSquares").on('value', function(snapshot){
            vm.setState({
                userSquaresCount: snapshot.numChildren()
            })
        })
        this.activeSquareCodesRef = base.syncState('activeSquareCodes', {
            context: this,
            state: 'activeSquareCodes',
        });
        this.tierMessagingRef = base.syncState(`tierMessaging`, {
            context: this,
            state: 'tierMessaging',
            asArray: true
        });
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
              if(!err){
                this.setGameToWatch(this.state.currentGame.id);
                this.getRedemptions(this.state.currentGame.id);
              }
              this.setState({loading:false})
            }
        });
    }

    setGameToWatch(id){
        if(this.userGameHistoryRef){
            appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        if(id){
            const vm = this;
            this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
                vm.setState({
                    userGameHistory: snapshot.numChildren()
                })
            })
        }
    }

    async downloadUsers(){
        this.setState({loading: true})
        let csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,How You Heard?,Opt-In,Opt-In 2,Square Completed,Prize,Code,Link,Time Completed\n';
        let users = await appDatabasePrimaryFunctions.ref("users").once('value').then(function(snapshot){
            return snapshot.val()
        });
        let answerList = await organizeUserAnswers(null, null, appDatabasePrimaryFunctions, this.state.currentGame);
        answerList[0].forEach(function(row) {
            let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
            csv += (users[row.uid].email || row.uid || "");
            csv+= ",";
            csv+= (cleanCSVText(firstName) || "");
            csv+= ",";
            csv+= (cleanCSVText(secondName) || "");
            csv+= ",";
            csv+= (users[row.uid].phoneNumber || "");
            csv+= ",";
            csv+= (cleanCSVText(users[row.uid].zipCode) || "");
            csv+= ",";
            csv+= (cleanCSVText(users[row.uid].address) || "");
            csv+= ",";
            csv+= (users[row.uid].birthday || "");
            csv+= ",";
            csv+= (cleanCSVText(users[row.uid].howYouHeard) || "");
            csv+= ",";
            csv+= (users[row.uid].optIn || "");
            csv+= ",";
            csv+= (users[row.uid].optInTwo || "");
            csv+= ",";
            csv+= (row.squareName || "");
            csv+= ",";
            csv+= (cleanCSVText(row.rewardSent) || "NONE");
            csv+= ",";
            csv+= (cleanCSVText(row.code) || "-");
            csv+= ",";
            csv+= (cleanCSVText(row.link) || "-");
            csv+= ",";
            csv += (typeof row.timeCompleted === "number" ? convertTimeStampToHumanReadable(row.timeCompleted) : row.timeCompleted);
            csv += "\n";
        });
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv"
        hiddenElement.download = "users_" + date_got;
        hiddenElement.click();
        this.setState({loading: false})
    }

    async getRedemptions(gameId) {
        if (!gameId) return;
        this.redeemedPrizeRef = base.listenTo(`redeemedPrize`, {
            context: this,
            queries: {
                orderByChild: 'gameId',
                equalTo: gameId
            },
            then(data){
                let countOfWinners = 0;
                for(const i in data){
                    if(data[i].redeemed){
                        countOfWinners++;
                    }
                }
                this.setState({
                    redemptions: countOfWinners
                })
            },
            onFailure(err){
                console.log(err)
            }
        })
    }

    componentWillUnmount() {
        if(this.userGameHistoryRef){
            appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        base.removeBinding(this.tenantVariablesRef);
        base.removeBinding(this.rewardsListRef);
        base.removeBinding(this.currentGameRef);
        appDatabasePrimaryFunctions.ref('userAnswers').off('value', this.userSquaresRef);
        appDatabasePrimaryFunctions.ref('emailsSent').off('value', this.emailsSentCountRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.emailVariablesRef);
        if(this.redeemedPrizeRef){
            base.removeBinding(this.redeemedPrizeRef);
        }
    }

    detectHowManyCodesEntered(codesArray, areLinks){
        if(!codesArray){
            return [];
        }
        codesArray = codesArray.trim();
        const splitOnLineBreaks = codesArray.split("\n");
        let splitOnWhiteSpace = codesArray.split(/\s+/);
        let splitOnCommas = codesArray.split(",");
        let splitArray = splitOnLineBreaks;
        if(splitOnWhiteSpace.length === splitOnCommas.length){
            splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
            splitOnCommas = codesArray.replace(/\s/g,'').split(",");
        }
        if(splitArray.length < splitOnWhiteSpace.length){
            splitArray = splitOnWhiteSpace
        }
        if(splitArray.length < splitOnCommas.length){
            splitArray = splitOnCommas
        }
        if(areLinks){
            for(const linkIndex in splitArray){
                const link = splitArray[linkIndex];
                if(!validUrl(link)){
                    return {message:"Invalid Url", inValidUrl: link};
                }
            }
        }
        return splitArray
    }

    async createGame(){
        let createGameObject = {};
        const gameId = appDatabasePrimaryFunctions.ref().push().key;
        const futureGames = this.state.futureGamesList || [];
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        let hasRewardWithAutoRedemptions = false;
        if(!startTime || !endTime){
            swal({
                title: "Hold On!",
                text: 'The game needs a start time and end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        startTime = new Date(startTime).getTime();
        endTime = new Date(endTime).getTime();
        if(startTime >= endTime){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be equal to or greater than the game end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        if(startTime <= Date.now()){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be before now!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        for(const fGameIndex in futureGames){
            const futureGame = futureGames[fGameIndex];
            if(startTime === futureGame.scheduleInfo.performAt){
                swal({
                    title: "Hold On!",
                    text: 'You already have a scheduled game at that time!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
                return
            }
        }
        let gameName = this.state.gameName.trim();
        let {addedItems} = this.state;
        if(addedItems.length === 0){
            swal({
                title: "Hold On!",
                text: 'The game needs some tiles added',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        if(!gameName){
            swal({
                title: "Hold On!",
                text: 'The game needs a name!',
                type: 'warning',
                confirmButtonText: 'Ok'
              });
            return
        }

        const rewardList = []
        const messaging_array = []
        for(var i = 0; i < addedItems.length + 1; i++){
            var index = i;
            var tiermessaging = {}
            tiermessaging.number_correct = index;
            var winningHeader = "Congrats!";
            var winningMessage = "You completed the square and won a prize! Check your email for your prize!";
            var winningMissedHeader = "Congrats!";
            var winningMissedMessage = "You completed the square!"
            if(this.state['winningWinnerHeader' + index.toString()] != undefined){
                winningHeader = this.state['winningWinnerHeader' + index.toString()]
            }
            if(this.state['winningWinnerMessage' + index.toString()] != undefined){
                winningMessage = this.state['winningWinnerMessage' + index.toString()]
            }
            if(this.state['winningMissedHeader' + index.toString()] != undefined){
                winningMissedHeader = this.state['winningMissedHeader' + index.toString()]
            }
            if(this.state['winningMissedMessage' + index.toString()] != undefined){
                winningMissedMessage = this.state['winningMissedMessage' + index.toString()]
            }

            tiermessaging.winningHeader = winningHeader;
            tiermessaging.winningMessage = winningMessage;
            tiermessaging.winningMissedHeader = winningMissedHeader;
            tiermessaging.winningMissedMessage = winningMissedMessage;

            const rewardsAdded = this.state['rewardsAdded' + i.toString()] || [];
            if(rewardsAdded.length !== 0 && !this.state.noPrizes){
                let totalAmount = 0;
                const readyToUploadPrizes = {};
                for(const rewardIndex in rewardsAdded){
                    const generatedRewardId = appDatabasePrimaryFunctions.ref().push().key;
                    const rewardVars = Object.assign({}, rewardsAdded[rewardIndex]);
                    readyToUploadPrizes[generatedRewardId] = Object.assign({}, rewardVars.reward);
                    readyToUploadPrizes[generatedRewardId]['amount'] = rewardVars.amount || "0";
                    totalAmount+=(parseInt(rewardVars.amount) || 0);
                    readyToUploadPrizes[generatedRewardId]['codes'] = rewardVars.codes || false;
                    readyToUploadPrizes[generatedRewardId]['allElsePrize'] = rewardVars.allElsePrize || false;
                    readyToUploadPrizes[generatedRewardId]['codesArray'] = rewardVars.codesArray || [];
                    readyToUploadPrizes[generatedRewardId]['linksArray'] = rewardVars.linksArray || [];
                    readyToUploadPrizes[generatedRewardId]['pinsArray'] = rewardVars.pinsArray || [];
                    readyToUploadPrizes[generatedRewardId]['used'] = 0;
                    readyToUploadPrizes[generatedRewardId]['isRedeemable'] = rewardVars.reward.isRedeemable || false;
                    if(readyToUploadPrizes[generatedRewardId]['isRedeemable']){
                        hasRewardWithAutoRedemptions = true;
                    }
                }
                readyToUploadPrizes["totalRewards"] = totalAmount;
                readyToUploadPrizes["totalRewardsUsed"] = 0;
                readyToUploadPrizes["randomPrizeOrder"] = this.state["randomOrder" + i.toString()] || false;
                readyToUploadPrizes["answerLevel"] = i;
                rewardList.push(readyToUploadPrizes);
            }
            messaging_array.push(tiermessaging)
        }

        if(rewardList.length === 0 && !this.state.noPrizes){
            swal({
                title: "Hold On!",
                text: 'You have no rewards added to the game! You need at least 1',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        createGameObject.gameName =  gameName;
        createGameObject.active =  false;
        createGameObject.addedSquares = addedItems;
        createGameObject.main_prize = null;
        createGameObject.other_prize = null;
        createGameObject.rewards = rewardList;
        createGameObject.gameType =  "scavenger_hunt_game";
        createGameObject.scheduleInfo = {};
        createGameObject.scheduleInfo['status'] = 'scheduled';
        createGameObject.scheduleInfo['performAt'] = startTime;
        createGameObject.scheduleInfo['endAt'] = endTime;
        createGameObject.hasRewardWithAutoRedemptions = hasRewardWithAutoRedemptions;
        if(this.state.add_map_image){
            const mapImage = this.state.mapImage;
            if(!mapImage || typeof mapImage !== "string"){
                swal({
                    title: "Hold On!",
                    text: "If you have add map image checked you need to upload an image",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
                this.setState({
                    loading:false
                })
                return;
            }
            createGameObject.mapImage = mapImage;
        } else {
            createGameObject.mapImage = null;
        }
        let currentGame = this.state.currentGame;
        createGameObject.id = gameId;
        if(currentGame.gameName){
            const vm = this;
            base.post('futureGamesList/'+ gameId, {
                data: createGameObject,
                async then(err){
                    if(!err){
                        vm.setState({
                            modal: false
                        })
                    }
                    const futureGameList = await vm.getFutureGames();
                    vm.setState({
                        futureGamesList: futureGameList,
                        loading:false
                    })
                    swal({
                        title: 'Game added to future games!',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                }
            })
        } else {
            createGameObject.timeStamp = Date.now();
            this.setState({
                currentGame: createGameObject,
                modal: false,
                loading: false,
                currentUserAnswersState: [],
                rewardsScoreboardAdded: [],
                rewardsAdded: []
            }, () => {
                this.getRedemptions(gameId);
            })
        }
    }

    stopGame(){
      let currentGame = this.state.currentGame;
      currentGame.active = false;
      this.setState({
        currentGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
        let currentGame = this.state.currentGame;
        this.setState({loading:true})
        currentGame.timeStamp = Date.now()
        currentGame.active = true;
        this.setState({
            currentGame: currentGame,
            loading: false
        });
        swal({
            title: 'Game Started',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    async makeFutureGameCurrent(gameToSetCurrent){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({futureGame: gameToSetCurrent, futureGameKey: gameToSetCurrent.id})
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
            return false;
        }
        const responseJson = await Promise.resolve(response.json());
        console.log(responseJson)
        if(responseJson.error){
            this.setState({loading: false});
            swal({
                title: "Oh No!",
                text: "Something went wrong, please try again!",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
        }
        const futureGameList = await this.getFutureGames();
        await this.getRedemptions(gameToSetCurrent.id);
        this.setState({
            futureGamesList: futureGameList,
            loading:false
        })
        swal({
            title: 'Game made current!',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    async removeGameFromFutureList(gameToRemoveId){
        var futureGameList = this.state.futureGamesList;
        for(var gameIndex in futureGameList){
            var futureGame = futureGameList[gameIndex];
            if(futureGame.id === gameToRemoveId){
                futureGameList.splice(gameIndex, 1)
                if(gameToRemoveId){
                    base.remove('futureGamesList/'+gameToRemoveId)
                }
            }
        }
        this.setState({
            futureGamesList: futureGameList
        })
    }

    async getFutureGames(numberOfResults = 10){
        return await base.fetch('futureGamesList', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'scheduleInfo/performAt'
            },
            then(data){
                return data
            }
        })
    }

    toDateTime(secs) {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    handleChange (evt) {
      let target = evt.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
      });
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then(async (result)=>{
          if(result.value){
              let currentGameState = await appDatabasePrimaryFunctions.ref('currentGame').once('value').then(function(snapshot){
                  return snapshot.val();
              });
              currentGameState.active = false;
              for(let rewardLevelIndex in currentGameState.rewards) {
                  const rewardLevel = currentGameState.rewards[rewardLevelIndex];
                  rewardLevel.totalRewardsUsed = 0;
                  for(let rewardLevelObjectParameter in rewardLevel){
                      const parameter = rewardLevel[rewardLevelObjectParameter];
                      if(typeof parameter === "object"){
                          parameter.used = 0
                      }
                  }
              }
              let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGameState.id).equalTo(currentGameState.id).once('value').then(function(snapshot){
                return snapshot.val();
              })
              for(const questionIndex in currentGameState.questions){
                  currentGameState.questions[questionIndex].shown = false
              }
              for(let historyRef in history){
                  let refInstance = history[historyRef];
                  if(refInstance && currentGameState.id && refInstance[currentGameState.id]){
                      appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGameState.id}`).set(null);
                  }
              }
              appDatabasePrimaryFunctions.ref("emailsSent").set(null);
              appDatabasePrimaryFunctions.ref("userSquares").set(null);
              appDatabasePrimaryFunctions.ref("prizeWon").set(null);
            this.setState({
                userSquares: [],
                currentGame: currentGameState,
                active: false
            })
          }
        })
    }

    navButtonClicked(direction){
      const currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        });
        this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'next' && currentLocation === 'third'){
          this.setState({
              wizardLocation: 'fourth',
          });
          this.toggleWizardLocation('fourth', 'first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'fourth'){
          this.setState({
              wizardLocation: 'third',
          });
          this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else {
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2, otherTab3){
      this.setState({
        wizardLocation: tabClicked
      });
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(otherTab3).classList.remove('active');
      document.getElementById(otherTab3).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
      document.getElementById(otherTab3+'1').classList.remove('active');
      document.getElementById(otherTab3+'1').classList.remove('show');
    }

    async switchStatsPrizes(buttonClicked){
        if(buttonClicked === "showStats"){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats:true,
                showPrizes:false,
                showGames:false
            })
        }else if(buttonClicked === "showPrizes"){
            document.getElementById('showPrizes').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            this.setState({
                loading: true
            })
            const result = await organizeUserAnswers(this.state.responseSize, null, appDatabasePrimaryFunctions, this.state.currentGame)
            this.setState({
                showStats:false,
                showPrizes:true,
                showGames:false,
                loading: false,
                currentUsersState: result[0],
                showLinkColumn: result[1]
            })
        } else if(buttonClicked === "showGames"){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            const futureGameList = await this.getFutureGames();
            this.setState({
                showStats:false,
                showPrizes:false,
                showGames:true,
                futureGamesList: futureGameList
            })
        }
    }

    addItemToBoard(){
        const currentlyAdded = this.state.addedItems;
        const makeFree = this.state.add_free_square || false;
        const currentyAddedIds = [];
        let hasFreeSquare = false;
        for(const currentlyAddedIndex in currentlyAdded){
            currentyAddedIds.push(currentlyAdded[currentlyAddedIndex].id)
            if(currentlyAdded[currentlyAddedIndex].isFreeSquare && makeFree) {
                hasFreeSquare = true;
            }
        }
        if(hasFreeSquare) {
            swal({
                title: 'Hold On!',
                text: "Only one free square is allowed and there is already one added",
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        const selectedSquareItemId = this.state.selectSquareItem;
        for(const squareItemIndex in this.state.squareItemsList){
            const squareItem = this.state.squareItemsList[squareItemIndex];
            if(selectedSquareItemId === squareItem.id && currentyAddedIds.indexOf(squareItem.id) === -1){
                squareItem.isFreeSquare = makeFree;
                currentlyAdded.push(squareItem)
            } else if(selectedSquareItemId === squareItem.id && currentyAddedIds.indexOf(squareItem.id) !== -1) {
                swal({
                    title: 'Already Added',
                    text: "Cannot add two of the same tile to the board",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                });
                return
            }
        }
        this.setState({
            addedItems: currentlyAdded
        })
    }

    removeFromAddedItems(index){
        const array = this.state.addedItems;
        array.splice(index, 1);
        this.setState({
            addedItems: array,
        })
    }

    saveReward (evt) {
        this.setState({ rewardToAdd: evt.target.value });
    }

    addRewardToLocalArray(index){
        const rewardAmount = this.state['rewardAmount' + index.toString()];
        let allElsePrize = this.state['allElsePrize' + index.toString()];
        if(index === 0){
            allElsePrize = true
        }
        const rewardId = this.state['ticket_select'+index.toString()];
        const codes = this.state['codes' + index.toString()];
        const links = this.state['links' + index.toString()];
        let codesArray = this.state['codesArray' + index.toString()] || '';
        let linksArray = this.state['linksArray' + index.toString()] || '';
        let pinsArray = this.state['pinsArray' + index.toString()] || '';
        codesArray = codesArray.replace(/\n/g, " ");
        linksArray = linksArray.replace(/\n/g, " ");
        pinsArray = pinsArray.replace(/\n/g, " ");
        let totalCodes = [];
        let totalLinks = [];
        let totalPins = [];
        if(codesArray.trim() === "" && codes && !allElsePrize){
            swal({
                title: 'Hold on!',
                text: 'Must enter codes or uncheck codes box!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if (codes && !allElsePrize){
            totalCodes = this.detectHowManyCodesEntered(codesArray);
            totalPins = this.detectHowManyCodesEntered(pinsArray);
            if(totalCodes.length !== parseInt(rewardAmount,10)){
                swal({
                    title: 'Codes not equal!',
                    text: "# of codes must equal number of rewards created. In this case: " +  totalCodes.length + " does not equal " + rewardAmount,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            } else if(totalPins.length !== 0 && totalPins.length !== parseInt(rewardAmount,10)){
                swal({
                    title: 'Pins not equal!',
                    text: "# of pins must equal number of rewards created. In this case: " +  totalPins.length + " does not equal " + rewardAmount,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }

        if(linksArray.trim() === "" && links && !allElsePrize){
            swal({
                title: 'Hold on!',
                text: 'Must enter links or uncheck links box!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if (links && !allElsePrize){
            totalLinks = this.detectHowManyCodesEntered(linksArray, true);
            if(totalLinks.inValidUrl){
                swal({
                    title: 'Invalid URL Detected!',
                    text: "Links must be valid urls. In this case: " +  totalLinks.inValidUrl + " is not valid",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
            if(totalLinks.length !== parseInt(rewardAmount,10)){
                swal({
                    title: 'Codes not equal!',
                    text: "# of links must equal number of rewards created. In this case: " +  totalLinks.length + " does not equal " + rewardAmount,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }

        if(!rewardId){
            swal({
                title: 'Missing field!',
                text: "Missing reward.  Can't add a reward to a game without selecting a reward",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        if(!allElsePrize && (!rewardAmount || rewardAmount < 1 || rewardId.length < 1)){
            swal({
                title: 'Missing Amount',
                text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const rewardToAddObject = {};
        for(let ticket in this.state.ticketList){
            if(this.state.ticketList[ticket].key === rewardId){
                rewardToAddObject.reward = this.state.ticketList[ticket];
                rewardToAddObject.allElsePrize = allElsePrize;
                rewardToAddObject.amount = allElsePrize ? null : rewardAmount;
                rewardToAddObject.codes = allElsePrize ? false : codes;
                rewardToAddObject.links = allElsePrize ? false : links;
                rewardToAddObject.codesArray = allElsePrize ? null : totalCodes;
                rewardToAddObject.linksArray = allElsePrize ? null : totalLinks;
                rewardToAddObject.pinsArray = allElsePrize ? null : totalPins;
            }
        }
        let array = this.state['rewardsAdded'+index.toString()] || [];
        let rewardsAddedIds = [];
        for(let prize in array){
            if(array[prize].allElsePrize && rewardToAddObject.allElsePrize){
                swal({
                    title: 'Hold On!',
                    text: "Cannot add two All Else Prizes to the same tier",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return
            }
            rewardsAddedIds.push(array[prize].reward.key)
        }
        if(rewardsAddedIds.indexOf(rewardId) === -1){
            array = array.concat(rewardToAddObject)
            this.setState({
                ['rewardsAdded'+index.toString()]: array,
                convertToQrCode: false,
                codes: false,
                links: false
            })
        } else {
            swal({
                title: 'Prize already added!',
                text: "You have already added this prize",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    removeFromToAddArray(index){
        let rewardArray = this.state.rewardsAdded;
        rewardArray.splice(index, 1)
        this.setState({
            rewardsAdded: rewardArray
        })
    }

    async setRewardRedeemed(reward, index){
        const result = await swal({
            title: 'Hold on!',
            text: 'This prizes redemption process is handled automatically by Sqwad, are you sure you want to toggle this?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Toggle',
        })
        if(!result.value)return;
        let uid = reward.uid || "";
        const currentGame = this.state.currentGame || {};
        const gameId = currentGame.id || "";
        const prizeWonId = reward.prizeWonId || reward.sendPrizeId || "";
        if(!uid || !gameId || !prizeWonId) return;
        this.setState({loading: true})
        let valueToSet = false;
        const redeemedPrizeRef = await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).once('value');
        const redeemedPrize = redeemedPrizeRef.val();
        if(redeemedPrize.redeemed){
            await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).update({redeemed: false, timeRedeemed: null});
        } else {
            valueToSet = true;
            await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).update({redeemed: true, timeRedeemed: new Date().getTime()});
        }
        const currentUsersState = this.state.currentUsersState;
        currentUsersState[index].isRedeemed = valueToSet;
        this.setState({
            currentUsersState: currentUsersState,
            loading: false
        })
    }

    onDrop(files, rejected, myArgument) {
        if(rejected.length > 0){
            swal({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        this.setState({loading:true})

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({loading:false})
            if(res.error){
                swal({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res.imageUrl) {
                const nameToUpdate = myArgument + "Preview"
                const fileToUpdate = files[0]
                this.setState({
                    mapImage: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
                swal({
                    title: 'Image cannot be uploaded',
                    text: "Something went wrong, please re-upload your image and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    render() {
        let selectedGame = this.state.currentGame || null;
        const mapImage = this.state.mapImage;
        let emailsSentCount = 0;
        const squareItemsList = this.state.squareItemsList || [];
        const userSquaresCount = this.state.userSquaresCount || 0;
        const currentlyAdded = this.state.addedItems || [];
        let hasRewardWithAutoRedemptions = false;
        let currentlyAddedLength = [];
        for (let i = 1; i < currentlyAdded.length+1; i++) {
            currentlyAddedLength.push(i);
        }
        const futureGamesList = this.state.futureGamesList || [];
        let players = this.state.userGameHistory || 0;
        let currentUsersState = this.state.currentUsersState || [];
        let startTimeFirstPart = null;
        let startTimeSecondPart = null;
        let endTimeSecondPart = null;
        let endTimeFirstPart = null;
        let selectedGameRewards = [];
        if(selectedGame && selectedGame.gameName){
            selectedGameRewards = selectedGame.rewards || [];
            emailsSentCount = this.state.emailsSentCount || 0;
            if(selectedGame.scheduleInfo){
                startTimeFirstPart = new Date(selectedGame.scheduleInfo.performAt).toLocaleDateString();
                startTimeSecondPart = new Date(selectedGame.scheduleInfo.performAt).toLocaleTimeString();
                endTimeFirstPart = new Date(selectedGame.scheduleInfo.endAt).toLocaleDateString();
                endTimeSecondPart = new Date(selectedGame.scheduleInfo.endAt).toLocaleTimeString();
            }
            hasRewardWithAutoRedemptions = selectedGame.hasRewardWithAutoRedemptions;
        } else {
            selectedGame = null;
        }
        const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-3">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && selectedGame.active ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Live</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && !selectedGame.active ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Not Live</p>
                  </div>
                </div>
                <div className="col-md-3">
                    <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: selectedGame && selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                    <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && !selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Start Game</button>
                </div>
                <div className="col-md-6" style={{textAlign:'right'}}>
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
                <div className="container">
                    <div className="row">
                        {startTimeFirstPart &&
                        <div className="col-md-4"
                             style={{color: "black", border: "solid 1px #d3d3d3", margin: 10, borderRadius: 5}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <u style={{color: "#353839"}}>Starts</u>
                                    <br/>
                                    <span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>
                                </div>
                                <div className="col-md-6">
                                    <u style={{color: "#353839"}}>Ends</u>
                                    <br/>
                                    {selectedGame && selectedGame.scheduleInfo && selectedGame.scheduleInfo.endAt  ?
                                        <span style={{fontSize: 18}}>{endTimeFirstPart}<br/>{endTimeSecondPart}</span>
                                        :
                                        <span style={{fontSize: 18}}>No Scheduled End</span>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                        <div className="col-md-6" style={{color: "black", border: "solid 1px #d3d3d3", marginTop:10, marginBottom:10, marginLeft:10, borderRadius: 5}}>
                            <div className="row">
                                <div className="col-md-3" style={{textAlign:'center'}}>
                                    <span style={{fontSize:12}}>Players</span>
                                    <footer className="value-text">{players}</footer>
                                </div>
                                <div className="col-md-3" style={{textAlign:'center'}}>
                                    <span style={{fontSize:12}}>Squares Completed</span>
                                    <footer className="value-text">{userSquaresCount}</footer>
                                </div>
                                <div className="col-md-3" style={{textAlign:'center'}}>
                                    <span style={{fontSize:12}}>Prizes Delivered</span>
                                    <footer className="value-text">{emailsSentCount}</footer>
                                </div>
                                {hasRewardWithAutoRedemptions &&
                                    <div className="col-3" style={{textAlign: 'center'}}>
                                        <span style={{fontSize: 12}}>Redemptions</span>
                                        <footer className="value-text">{this.state.redemptions || 0}</footer>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Future Games</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                    <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                        <span className="fa fa-arrow-circle-down"/> Download Participants
                    </div>
                    <div style={{height:'10px', width:'100%'}}/>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <th>Square Name</th>
                        <th>Time Completed</th>
                        <th>Prize Sent</th>
                        <th>Code</th>
                          {this.state.showLinkColumn &&
                            <th>Link</th>
                          }
                          {hasRewardWithAutoRedemptions &&
                              <th>Redeemed</th>
                          }
                      </tr>
                      {
                          currentUsersState.map(function(item,i){
                            return <tr key={i}>
                                <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                                <td style={{fontFamily:'Open Sans'}}>{item.squareName}</td>
                                <td style={{fontFamily:'Open Sans' }}>{typeof item.timeCompleted === "number" ? convertTimeStampToHumanReadable(item.timeCompleted) : item.timeCompleted}</td>
                                <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                                <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                                {this.state.showLinkColumn &&
                                    <td style={{fontFamily:'Open Sans' }}>{item.link || "NONE"}</td>
                                }
                                {hasRewardWithAutoRedemptions &&
                                    <td style={{fontFamily: 'Open Sans'}}>
                                        <input type="checkbox" id={i} data-switch="success" checked={item.isRedeemed} onClick={() => this.setRewardRedeemed(item, i)} readOnly/>
                                        <label htmlFor={i} data-on-label="Yes" data-off-label="No"/>
                                    </td>
                                }
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Partner Links</h4>
                                <a href="/gameQrHub">See Game QR Codes</a>
                                <h4 style={{color:'black'}}>Prizes</h4>
                                {selectedGameRewards && selectedGameRewards.length > 0 && valueDoesExist(selectedGameRewards[0].answerLevel) ?
                                    <Accordion style={{margin:10}}>
                                        {
                                            selectedGameRewards.sort(function(a,b){return a.answerLevel - b.answerLevel;}).map(function (item, i) {
                                                const rewards = [];
                                                let allElseReward;
                                                for(const rewardObjetIndex in item){
                                                    const reward = item[rewardObjetIndex];
                                                    if(typeof reward === "object"){
                                                        if(reward.allElsePrize){
                                                            allElseReward = reward;
                                                        } else {
                                                            rewards.push(reward);
                                                        }
                                                    }
                                                }
                                                return <AccordionItem className="blackBackgroundColor" bodyClassName="changeBackgroundColor" key={i} title={`${(item.answerLevel).toString()} correct`} expanded={i === 0}>
                                                    {
                                                        rewards.map(function(reward, index){
                                                            return <div style={{color:"black"}}>
                                                                <span>{reward.amount}, {reward.rewardName}</span>
                                                            </div>
                                                        })
                                                    }
                                                    {allElseReward &&
                                                    <div style={{color:"black"}}>
                                                        {rewards.length > 0 &&
                                                        <br/>
                                                        }
                                                        <span><span style={{color:"grey", fontWeight: 100, fontFamily: "sans-serif"}}>Everyone Else Wins</span> {allElseReward.rewardName}</span>
                                                    </div>
                                                    }
                                                </AccordionItem>
                                            })
                                        }
                                    </Accordion>
                                    :
                                    <>
                                        {selectedGameRewards && selectedGameRewards.length > 0 ?
                                            <>
                                                {selectedGameRewards.map(function(reward, index){
                                                    return <div style={{color:'black'}}>
                                                        {reward.rewardName}
                                                    </div>
                                                })}
                                            </>
                                            :
                                            <span style={{color:'black'}}>
                                    No Prizes Added
                                  </span>
                                        }
                                    </>
                                }
                            </div>
                            <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Details</h4>
                                {selectedGame && selectedGame.gameName &&
                                <div style={{textAlign:'left', color:'black'}}>
                                    Game Name: {selectedGame.gameName}
                                    <br/>
                                    Game Board:
                                    <div align="left" style={{width:180, minHeight: 320, outline: "1px black solid"}}>
                                        {
                                            selectedGame.addedSquares.map(function (item, index) {
                                                return (
                                                    <div key={index} style={{
                                                        display: "inline-block",
                                                        width: 60,
                                                        height: 60,
                                                        border: "1px black solid"
                                                    }}>
                                                        <img width="60px" height="60px" src={item.image} alt=""/>
                                                    </div>)
                                            }, this)
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                  <div style={{display: this.state.showGames ? 'block' : 'none', marginLeft: 10}}>
                      <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                      <table className="table table-striped" style={{color:'black'}}>
                          <tbody>
                          <tr>
                              <th>Name</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Make Current Game</th>
                              <th>Delete Game</th>
                          </tr>
                          {
                              futureGamesList.map(function(item,i){
                                  let dateTime = convertTimeStampToHumanReadable(item.scheduleInfo.performAt)
                                  let endTime = convertTimeStampToHumanReadable(item.scheduleInfo.endAt)
                                  return <tr key={i}>
                                      <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                                      <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                                      <td style={{fontFamily:'Open Sans' }}>{endTime}</td>
                                      <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.makeFutureGameCurrent(item)}>MAKE CURRENT</button></td>
                                      <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                                  </tr>
                              }, this)
                          }
                          </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-square"/>
                                <span className="d-none d-sm-inline"> Create Board</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('fourth', 'first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="fourth1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="startTime"> Set Start Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.startTime} onChange={date => this.setState({startTime: date})}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="endTime"> Set End Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.endTime} onChange={date => this.setState({endTime: date})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="tab-pane" id="second">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="selectSquareItem"> Add Square Item</label>
                                        <div className="col-md-9">
                                            <select className="form-control" name="selectSquareItem" id="selectSquareItem" value={this.state.selectSquareItem} onChange={this.handleChange}>
                                                <option/>
                                                {
                                                    squareItemsList.map(function(item, index){
                                                        return (
                                                            <option value={item.key} key={index}>{item.squareName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <button style={{marginTop:5}} className="btn btn-success" onClick={()=>this.addItemToBoard()}>Add Square</button>
                                            <button className="btn btn-primary" style={{display: this.state.addedItems.length>0?"":"none", marginLeft:5, marginTop:5}} onClick={()=>this.setState({deleteAddedItems:!this.state.deleteAddedItems})}>Edit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{marginLeft:10}}>
                                <div className="form-check row mb-3">
                                    <input className="form-check-input" id="add_free_square" name="add_free_square" type="checkbox" checked={this.state.add_free_square} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="add_free_square"> Make This Square Free</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" align="center">
                                    <div align="left" style={{width:180, minHeight: 320, outline: "1px black solid"}}>
                                        { this.state.addedItems.length > 0 ? (
                                                this.state.addedItems.map(function(item, index){
                                                        return(
                                                            <div key={item} style={{display: "inline-block", width:60, height:60}}>
                                                                <span style={{position:'absolute', zIndex:1, display:this.state.deleteAddedItems?"":"none"}} onClick={()=>this.removeFromAddedItems(index)}>❌</span>
                                                                {item.isFreeSquare &&
                                                                    <div className="overlay">FREE</div>
                                                                }
                                                                <img width="60px" height="60px" src={item.image} alt=""/>
                                                            </div>
                                                        )
                                                }, this)
                                            ): (
                                                <div>No Items Added Yet</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{marginLeft:10}}>
                                    <div className="form-check row mb-3">
                                        <input className="form-check-input" id="add_map_image" name="add_map_image" type="checkbox" checked={this.state.add_map_image} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="add_map_image"> Add Map?</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{display: this.state.add_map_image ? "" : "none"}}>
                                <div className="col-12">
                                    <div className="form-group" align="center">
                                        <label htmlFor="scrambleImage" style={{width:'100%'}}>Map Image</label>
                                        <Dropzone
                                            className="dropzone dz-clickable"
                                            accept="image/*"
                                            onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'mapImage')}}
                                            multiple={false}
                                            maxSize={2200000}
                                            style={{width:600}}>
                                            <div className="dz-message needsclick">
                                                <span className="fa fa-cloud-upload text-muted" style={{display: mapImage ? 'none' : ''}}/>
                                                <h3 style={{display: mapImage ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                                <img
                                                    style={{display: mapImage ? '' : 'none'}}
                                                    src={mapImage}
                                                    width="100px"
                                                    height="auto"
                                                    alt="Drop your image here"
                                                />
                                            </div>
                                        </Dropzone>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="third">
                            <div className="form-horizontal">
                                <div className="row">
                                    <div className="col-12" style={{textAlign:'center'}}>
                                        <div className="form-check">
                                            <input value={this.state.noPrizes} className="form-check-input" id="noPrizes" name="noPrizes" type="checkbox" checked={this.state.noPrizes} onChange={this.handleChange} />
                                            <label className="form-check-label" htmlFor="codes">No Prizes</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Accordion style={{margin:10}}>
                                            {
                                                currentlyAddedLength.map(function(item,index){
                                                    var winningHeader = "Congrats!"
                                                    var winningMessage = "You completed the square and won a prize! Check your email for your prize!"
                                                    var winningMissedHeader = "Congrats!"
                                                    var winningMissedMessage = "You completed the square!";
                                                    if(this.state['winningWinnerHeader' + item.toString()] != undefined){
                                                        winningHeader = this.state['winningWinnerHeader' + item.toString()]
                                                    }
                                                    if(this.state['winningWinnerMessage' + item.toString()] != undefined){
                                                        winningMessage = this.state['winningWinnerMessage' + item.toString()]
                                                    }
                                                    if(this.state['winningMissedHeader' + item.toString()] != undefined){
                                                        winningMissedHeader = this.state['winningMissedHeader' + item.toString()]
                                                    }
                                                    if(this.state['winningMissedMessage' + item.toString()] != undefined){
                                                        winningMissedMessage = this.state['winningMissedMessage' + item.toString()]
                                                    }
                                                    let squaresText = "square";
                                                    if(item > 1){
                                                        squaresText = "squares";
                                                    }
                                                    return(
                                                        <AccordionItem key={item} className="if-number-correct-item" title={`If a fan fills ${(item).toString()} ${squaresText}`} expanded={item === 0}>
                                                            {!this.state.noPrizes &&
                                                            <span>
                                                  <div className="form-group row mb-3">
                                                    <label className="col-md-3 col-form-label" htmlFor={`ticket_select${(item).toString()}`}> Select Prize</label>
                                                    <div className="col-md-6">
                                                      <select className="form-control" name={`ticket_select${(item).toString()}`} id={`ticket_select${(item).toString()}`} value={this.state['ticket_select' + item.toString()]} onChange={this.handleChange}>
                                                        <option/>
                                                          {
                                                              this.state.ticketList.map(function (item, index) {
                                                                  return (
                                                                      <option value={item.key}
                                                                              key={index}>{item.rewardName}</option>
                                                                  )
                                                              })
                                                          }
                                                      </select>
                                                    </div>
                                                    <div className="col-md-3" style={{alignSelf:"center", textAlign: "right"}}>
                                                      <div className="form-check">
                                                        <input id={`allElsePrize${(item).toString()}`} className="form-check-input" name={`allElsePrize${(item).toString()}`} type="checkbox" checked={this.state['allElsePrize' + item.toString()] || item === 0} onChange={this.handleChange} />
                                                        <label className="form-check-label" htmlFor={`allElsePrize${(item).toString()}`}>All Else Prize</label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: item > 0 && !this.state['allElsePrize' + item.toString()] ? 'flex' : 'none', alignItems:'center'}}>
                                                    <label className="col-md-3 col-form-label" htmlFor={`rewardAmount${(item).toString()}`}> How Many Of This Prize To Give Out?</label>
                                                    <div className="col-md-4">
                                                      <input id={`rewardAmount${(item).toString()}`} name={`rewardAmount${(item).toString()}`} type="number" className="form-control" value={this.state['rewardAmount' + item.toString()]} onChange={this.handleChange} placeholder="5" />
                                                    </div>
                                                    <div className="form-check">
                                                      <input id={`codes${(item).toString()}`} className="form-check-input" name={`codes${(item).toString()}`} type="checkbox" checked={this.state['codes' + item.toString()]} onChange={this.handleChange} />
                                                      <label className="form-check-label" htmlFor={`codes${(item).toString()}`}>Add Codes</label>
                                                    </div>
                                                    <div className="form-check" style={{marginLeft: 5}}>
                                                      <input id={`links${(item).toString()}`} className="form-check-input" name={`links${(item).toString()}`} type="checkbox" checked={this.state['links' + item.toString()]} onChange={this.handleChange} />
                                                      <label className="form-check-label" htmlFor={`links${(item).toString()}`}>Add Links</label>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: this.state['codes' + item.toString()] && !this.state['allElsePrize' + item.toString()] ? 'flex' : 'none'}}>
                                                    <label htmlFor={`codesArray${(item).toString()}`} className="col-md-3 col-form-label">Enter Codes (code1,code2)</label>
                                                    <div className="col-md-9">
                                                      <textarea value={this.state['codesArray' + item.toString()]} className="form-control" name={`codesArray${(item).toString()}`} onChange={this.handleChange} placeholder="code1,code2,code3"/>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: this.state['codes' + item.toString()] && !this.state['allElsePrize' + item.toString()] ? 'flex' : 'none'}}>
                                                    <label htmlFor={`pinsArray${(item).toString()}`} className="col-md-3 col-form-label">Enter Pins</label>
                                                    <div className="col-md-9">
                                                      <textarea value={this.state['pinsArray' + item.toString()]} className="form-control" name={`pinsArray${(item).toString()}`} onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" style={{display: this.state['links' + item.toString()] && !this.state['allElsePrize' + item.toString()] ? 'flex' : 'none'}}>
                                                    <label htmlFor={`linksArray${(item).toString()}`} className="col-md-3 col-form-label">Enter links</label>
                                                    <div className="col-md-9">
                                                      <textarea value={this.state['linksArray' + item.toString()]} className="form-control" name={`linksArray${(item).toString()}`} onChange={this.handleChange} placeholder="https://myfirstlink.com, https://mysecondlink.com, https://mythirdlink.com"/>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mb-3" align="center">
                                                      <div className="col-md-12">
                                                          <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray(item)}>Add Prize</button>
                                                      </div>
                                                  </div>
                                                                {this.state['rewardsAdded'+item.toString()] && this.state['rewardsAdded'+item.toString()].length > 1 &&
                                                                <div className="form-check" >
                                                                    <input value={this.state['randomOrder'+item.toString()]} className="form-check-input" id={`randomOrder${(item).toString()}`} name={`randomOrder${(item).toString()}`} type="checkbox" checked={this.state['randomOrder' +item.toString()]} onChange={this.handleChange} />
                                                                    <label className="form-check-label" htmlFor={`randomOrder${(item).toString()}`}>Give Out In Random Order</label>
                                                                </div>
                                                                }
                                                                <div className="form-group mb-3">
                                                      <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                                          {
                                                              this.state['rewardsAdded'+item.toString()] && this.state['rewardsAdded'+item.toString()].map(function(item,addedIndex){
                                                                  let codesText = "No"
                                                                  if(item.codes){
                                                                      codesText = "Yes"
                                                                  }
                                                                  return(
                                                                      <div key={addedIndex} className="form-group">
                                                                          <li>) {item.amount || "All Else Prize"} - {item.reward.rewardName}{item.amount && "; Codes: "+codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(addedIndex,item)}/></li>
                                                                      </div>
                                                                  )
                                                              })
                                                          }
                                                      </ol>
                                                  </div>
                                                </span>
                                                            }
                                                            <div className="form-check">
                                                                <input className="form-check-input" name={`textEditChanges${(item).toString()}`} type="checkbox" checked={this.state['textEditChanges' + item.toString()]} onChange={this.handleChange} />
                                                                <label className="form-check-label" htmlFor={`textEditChanges${(item).toString()}`}>Advanced Text Changes</label>
                                                            </div>
                                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + item.toString()] && !this.state.noPrizes ? 'flex' : 'none', alignItems: "center" }}>
                                                                <label htmlFor={`winningWinnerHeader${(item).toString()}`} className="col-md-3 col-form-label">Winner Header</label>
                                                                <div className="col-md-9">
                                                                    <input value={winningHeader} className="form-control" name={`winningWinnerHeader${(item).toString()}`} type="text" onChange={this.handleChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + item.toString()] && !this.state.noPrizes ? 'flex' : 'none', alignItems: "center" }}>
                                                                <label htmlFor={`winningWinnerMessage${(item).toString()}`} className="col-md-3 col-form-label">Winner Message</label>
                                                                <div className="col-md-9">
                                                                    <input value={winningMessage} className="form-control" name={`winningWinnerMessage${(item).toString()}`} type="text" onChange={this.handleChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + item.toString()] && item > 0 ? 'flex' : 'none', alignItems: "center" }}>
                                                                <label htmlFor={`winningMissedHeader${(item).toString()}`} className="col-md-3 col-form-label">Winner But No Prize Header</label>
                                                                <div className="col-md-9">
                                                                    <input value={winningMissedHeader} className="form-control" name={`winningMissedHeader${(item).toString()}`} type="text" onChange={this.handleChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row mb-3" style={{display: this.state['textEditChanges' + item.toString()] && item > 0 ? 'flex' : 'none', alignItems: "center" }}>
                                                                <label htmlFor={`winningMissedMessage${(item).toString()}`} className="col-md-3 col-form-label">Winner But No Prize Message</label>
                                                                <div className="col-md-9">
                                                                    <input value={winningMissedMessage} className="form-control" name={`winningMissedMessage${(item).toString()}`} type="text" onChange={this.handleChange}/>
                                                                </div>
                                                            </div>
                                                        </AccordionItem>
                                                    );
                                                }, this)
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="fourth">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>

                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'fourth' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'fourth' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
