import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import sampleSponsorLogo from '../../styles/images/sponsor_logo.png';
import sampleAppTopImage from '../../styles/images/top_icon.png';
import appBackgroundImage from '../../styles/images/trivia_background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import { Modal } from 'reactstrap';
import { PhotoshopPicker } from 'react-color';


class SetUpBrandingVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOne: true,
            showTabTwo: false,
            showTabThree: false,
            loading:true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleSubmit(event) {
      event.preventDefault();
      let variables = this.state.tenantVariables || {};
      let vm = this;
      base.post("tenantVariables/", {
        data: variables,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Tenant Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            console.log(err)
          }
        }
      })
    }

    handleChange (evt) {
      let tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[evt.target.name] = evt.target.value
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleImageChange(event){
        const name_of_file = event.target.name;
        const target = this[name_of_file];
        const file_to_update =target.files[0];
        this.setState({loading:true})
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false})
            if(res.error){
                swal({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res.imageUrl) {
                let tenantVariablesCopy = vm.state.tenantVariables;
                tenantVariablesCopy[name_of_file] = res.imageUrl
                vm.setState({
                    tenantVariables: tenantVariablesCopy,
                }, () => {
                    vm[name_of_file].value = ""
                });
            } else {
                swal({
                    title: 'Image cannot be uploaded',
                    text: "Something went wrong, please re-upload your image and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    switchTextShowing(element){
      if(element === "first"){
        document.getElementById('showfirst').classList.add('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: true,
          showTabTwo: false,
          showTabThree: false,
        })
      }else if(element === "second"){
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: false,
          showTabTwo: true,
          showTabThree: false,
        })
      } else {
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.add('active');
        this.setState({
          showTabOne: false,
          showTabTwo: false,
          showTabThree: true,
        })
      }
    }

    openColorPicker(colorToChange){
        let oldColor = this.state.tenantVariables[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        let colorToChange = this.state.colorToUpdate;
        let tenantVariables = this.state.tenantVariables;
        tenantVariables[colorToChange] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariables,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    toggleColors(){
        if(this.state.colorsTab){
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({colorsTab: !this.state.colorsTab})
    }

    render() {
        const topHeaderImage = this.state.tenantVariables.topHeaderImage || sampleAppTopImage;
        const filledInIcon = this.state.tenantVariables.filledInIcon || sampleSponsorLogo;
        const backgroundImage = this.state.tenantVariables.backgroundImage || appBackgroundImage;
        const frontImage = this.state.tenantVariables.frontLogoImage || sampleAppFrontImage;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                      <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.switchTextShowing('first')}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirst">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Fan Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.switchTextShowing('third')}>
                              <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showthird">
                                  <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Game Text</span>
                              </a>
                          </li>
                      </ul>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabOne === true ? '' : 'none'}}>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showColors">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Colors</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showImages">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Images</span>
                            </a>
                        </li>
                    </ul>
                      <div style={{display: this.state.showTabOne ? 'block' : 'none'}}>
                          <div style={{display: this.state.colorsTab ? 'block': 'none'}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Button Colors</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="primaryColor" style={{marginRight: 10}}>Button Color</label>
                                        <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="secondaryColor" style={{marginRight: 10}}>Button Text Color</label>
                                        <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4>Other Colors</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logOutButtonColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="logOutButtonColor" style={{marginRight: 10}}>Logout Color</label>
                                        <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("textColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.textColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="textColor" style={{marginRight: 10}}>Text Color</label>
                                        <input id="textColor" name="textColor" type="text" className="form-control" value={this.state.tenantVariables.textColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("customFgColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.customFgColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="customFgColor" style={{marginRight: 10}}>QRCode Front Color</label>
                                        <input id="customFgColor" name="customFgColor" type="text" className="form-control" value={this.state.tenantVariables.customFgColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("customBgColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.customBgColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="customBgColor" style={{marginRight: 10}}>QRCode Background Color</label>
                                        <input id="customBgColor" name="customBgColor" type="text" className="form-control" value={this.state.tenantVariables.customBgColor} onChange={this.handleChange} placeholder="#fff" style={{marginRight:10}}/>

                                    </div>
                                </div>
                            </div>
                          </div>
                          <div style={{display: this.state.colorsTab ? 'none' : 'block'}}>
                            <div className="admin-grid-container three-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                              <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={topHeaderImage} width="160" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="topHeaderImage">Header Image</label>
                                <div className="form-group">
                                  <input style={{display:'none'}} id="topHeaderImage" name="topHeaderImage" type="file" ref={input => {this.topHeaderImage = input; }} onChange={this.handleImageChange}/>
                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topHeaderImage').click()} />
                                </div>
                              </div>
                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                    <img src={backgroundImage} width="160" height="auto" alt=""/>
                                    <br/>
                                    <label htmlFor="backgroundImage">Phone Background Image</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                    </div>
                                </div>
                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                    <img src={frontImage} width="150" height="auto" alt=""/>
                                    <br/>
                                    <label htmlFor="frontLogoImage">Front Logo</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                    </div>
                                </div>
                              <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={filledInIcon} width="150" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="filledInIcon">Filled In Icon<br/></label>
                                <div className="form-group">
                                  <input style={{display:'none'}} id="filledInIcon" name="filledInIcon" type="file" ref={input => {this.filledInIcon = input; }} onChange={this.handleImageChange}/>
                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('filledInIcon').click()} />
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div style={{display: this.state.showTabThree ? 'block' : 'none'}}>
                          {/*<div className="form-group">*/}
                          {/*    <label htmlFor="winningHeader">Completed Header Text:</label>*/}
                          {/*    <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={this.state.tenantVariables.winningHeader} onChange={this.handleChange} placeholder="You Won!"/>*/}
                          {/*</div>*/}
                          {/*<div className="form-group">*/}
                          {/*    <label htmlFor="winningBody">Completed Body Text:</label>*/}
                          {/*    <input id="winningBody" name="winningBody" type="text" className="form-control" value={this.state.tenantVariables.winningBody} onChange={this.handleChange} placeholder="You should receive your winning email shortly!"/>*/}
                          {/*</div>*/}
                          {/*<div className="form-group">*/}
                          {/*    <label htmlFor="winningNoPrizeHeader">Completed No Prize Header Text:</label>*/}
                          {/*    <input id="winningNoPrizeHeader" name="winningNoPrizeHeader" type="text" className="form-control" value={this.state.tenantVariables.winningNoPrizeHeader} onChange={this.handleChange} placeholder="You Won!"/>*/}
                          {/*</div>*/}
                          {/*<div className="form-group">*/}
                          {/*    <label htmlFor="winningNoPrizeBody">Completed No Prize Body Text:</label>*/}
                          {/*    <input id="winningNoPrizeBody" name="winningNoPrizeBody" type="text" className="form-control" value={this.state.tenantVariables.winningNoPrizeBody} onChange={this.handleChange} placeholder="You won but all of the prizes have been given out!"/>*/}
                          {/*</div>*/}
                            <div className="form-group">
                                <label htmlFor="pageTitle">Page Title:</label>
                                <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Sponsor Blackout"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="noSquareHeader">No Square Header Text:</label>
                                <input id="noSquareHeader" name="noSquareHeader" type="text" className="form-control" value={this.state.tenantVariables.noSquareHeader} onChange={this.handleChange} placeholder="No Squares Up!"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="noSquareBody">No Square Body Text:</label>
                                <input id="noSquareBody" name="noSquareBody" type="text" className="form-control" value={this.state.tenantVariables.noSquareBody} onChange={this.handleChange} placeholder="Come back later to play"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="scannerText">Above QR Scanner Text:</label>
                                <input id="scannerText" name="scannerText" type="text" className="form-control" value={this.state.tenantVariables.scannerText} onChange={this.handleChange} placeholder="Use this scanner to scan the item QR code"/>
                            </div>
                            <div className="form-group">
                              <label htmlFor="showMapButtonText">Show Map Button Text:</label>
                              <input id="showMapButtonText" name="showMapButtonText" type="text" className="form-control" value={this.state.tenantVariables.showMapButtonText} onChange={this.handleChange} placeholder="Show Map"/>
                            </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
              <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                  <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
              </Modal>
         </div>
        );
    }
}

export default SetUpBrandingVariables;
