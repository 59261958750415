import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base} from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from "react-dropzone";
import UploadImage from '../utils/UploadImage';
import RichTextEditor from 'html-verify-react-rte';
const QRCode = require('qrcode.react');

class SetUpSquareItem extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            squareItemsList: [],
            editingItem: false,
            editSquareItemImage: '',
            editSquareItemImagePreview: {},
            selectedItem: {},
            currentItemName: '',
            imageUrl: null,
            modal: false,
            loading: true
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.squareItemsListRef = base.syncState(`squareItemsList`, {
            context: this,
            state: 'squareItemsList',
            asArray: true
        });
        this.setState({loading: false})
    }

    componentWillUnmount() {
        base.removeBinding(this.squareItemsListRef);
        base.removeBinding(this.tenantVariablesRef);
    }

    handleChange(evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleRichTextChange = (name, text) => {
        this.setState({ [name]:  text});
    }

    getUrlToAttachToCode(){
        return process.env.REACT_APP_FAN_LINK || false;
    }

    createSquareItem() {
        const imageUrl = this.state.imageUrl || this.state.editSquareItemImage;
        const squareName = this.state.currentItemName;
        let squareInfoText = this.state.squareInfoText || RichTextEditor.createEmptyValue();
        squareInfoText = squareInfoText.toString('html');
        if(squareInfoText === "<p><br></p>"){
            squareInfoText = "";
        }
        let code = (this.state.selectedItem && this.state.selectedItem.code) || appDatabasePrimaryFunctions.ref().push().key;
        if (!squareName) {
            swal({
                title: "No Name Entered",
                text: 'Make sure to enter a name for your tile!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        if(!imageUrl){
            swal({
                title: 'No image detected!',
                text: 'Please enter upload one and try again',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return;
        }
        let selectedItem = this.state.selectedItem;
        if (Object.keys(selectedItem).length === 0) {
            selectedItem = null
        }
        const vm = this;
        let squareId = (vm.state.selectedItem && (vm.state.selectedItem.key || vm.state.selectedItem.id)) || appDatabasePrimaryFunctions.ref().push().key;
        const getStartOfUrl = this.getUrlToAttachToCode();
        let codeUrl = null;
        if(getStartOfUrl){
            codeUrl = getStartOfUrl + "?passedcode=" + code + "&passedsquareid=" + squareId;
        }
        this.setState({loading:true})
        base.post(`squareItemsList/${squareId}`, {
            data: {squareName: squareName, image: imageUrl, id: squareId, code: code, codeUrl: codeUrl, squareInfoText: squareInfoText},
            then(err) {
                if (!err) {
                    swal({
                        title: vm.state.selectedItem ? 'Edited!':'Created!',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                    vm.setState({
                        modal: false,
                        currentItemName: "",
                        squareInfoText: RichTextEditor.createEmptyValue(),
                        selectedItem: {},
                        imageUrl: null,
                        loading:false
                    });
                } else {
                    vm.setState({loading:false})
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }
        });
    }

    editItem(event) {
        event.preventDefault();
        const array = this.state.squareItemsList;
        const item = array[event.target.value];
        let advanced = false;
        if(item.squareInfoText){
            item.squareInfoText = RichTextEditor.createValueFromString(item.squareInfoText, 'html')
            advanced = true;
        }
        this.setState({
            modal: !this.state.modal,
            editingItem: true,
            currentItemName: item.squareName,
            squareInfoText: item.squareInfoText || RichTextEditor.createEmptyValue(),
            editSquareItemImage: item.image,
            selectedItem: item,
            advanced: advanced
        });
    }

    deleteItem(e) {
        e.preventDefault();
        const array = this.state.squareItemsList;
        const index = array[e.target.value];
        swal({
            title: 'Delete square item?',
            text: 'Are you sure you want to do this? You will no longer be able to use this square item in any games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result) {
                base.remove('squareItemsList/' + index.key, function (err) {
                    if (err) {
                        swal({
                            title: 'There was some error!',
                            text: 'Try again and if the problem persists try logging out and logging back in',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                })
            }
        })
    }

    onDrop(files, rejected, myArgument) {
        if(rejected.length > 0){
            swal({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        this.setState({loading:true})

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({loading:false})
            if(res.error){
                swal({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res.imageUrl) {
                const nameToUpdate = myArgument + "Preview"
                const fileToUpdate = files[0]
                this.setState({
                    imageUrl: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
                swal({
                    title: 'Image cannot be uploaded',
                    text: "Something went wrong, please re-upload your image and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            editingItem: false,
            selectedItem: {},
            selectedPartner: {},
            editSquareItemImage: "",
            currentItemName: '',
            squareInfoText: RichTextEditor.createEmptyValue(),
            imageUrl: null,
            editSquareItemImagePreview: {},
            advanced: false
        });
    }

    downloadQrCode(item){
        const canvas = document.getElementById(item.id);
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${item.squareName}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const customFgColor = tenantVariables.customFgColor || "#000";
        const customBgColor = tenantVariables.customBgColor || "#fff"
        const squareItemsList = this.state.squareItemsList;
        let selectedItem = this.state.selectedItem;
        const editSquareItemImagePreview = this.state.editSquareItemImagePreview;
        if (Object.keys(selectedItem).length === 0) {
            selectedItem = null;
        }
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        if(!isMlbApp){
            toolbarConfig.display.push('LINK_BUTTONS')
        }

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none'}}/>
                <SideMenu/>
                <TopMenu/>
                <div className="admin-main-panel">
                    <div className="card">
                        <div className="card-body">
                            <p className="admin-header-text" style={{marginBottom: 0}}>Square Items</p>
                            <p className="admin-subheader-text">These are square items fans will try to fill out</p>
                            <button className="btn btn-primary btn-lg create-prize-button"
                                    style={{fontSize: 20, marginLeft: 20}} onClick={this.toggle}>Add Square Item
                            </button>
                            <div className="admin-grid-container four-columns" style={{marginTop: 20}}>
                                {
                                    squareItemsList.map(function (item, i) {
                                        return <div key={i} className="card">
                                            <div className="card-body" align="center">
                                                <p style={{marginTop: 5}}>{item.squareName}</p>
                                                <p>
                                                    <img width="80%" height="auto" src={item.image} alt=""/>
                                                </p>
                                                <div style={{width:"100%",display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                                    <QRCode size={512} level={'H'} id={item.id} style={{marginTop:10, marginBottom: 10, maxWidth: 128, height:'auto'}} value={item.codeUrl || item.code} fgColor={customFgColor} bgColor={customBgColor}/>
                                                    <button style={{color:'black', fontSize: 30, marginLeft: 10, backgroundColor: 'transparent', border:'none'}} className="fa fa-download" onClick={()=> this.downloadQrCode(item)}/>
                                                </div>
                                                <button className="btn btn-primary btn-lg edit-button"
                                                        style={{marginRight: 5, marginBottom: 10}}
                                                        onClick={this.editItem.bind(this)} value={i}><span
                                                    className="fa fa-ellipsis-v"/> Edit
                                                </button>
                                                <button className="btn btn-primary btn-lg delete-button"
                                                        style={{marginBottom: 10}}
                                                        onClick={this.deleteItem.bind(this)} value={i}><span
                                                    className="fa fa-trash-o"/> Delete
                                                </button>
                                            </div>
                                        </div>
                                    }, this)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
                    <ModalHeader toggle={this.toggle}>Add Square Item</ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <div className="form-group">
                                    <label htmlFor="currentItemName">Square Item Name</label>
                                    <input type="text" id="currentItemName" name="currentItemName"
                                              className="form-control" value={this.state.currentItemName}
                                              onChange={this.handleChange}
                                              placeholder="Mcdonalds McChicken Promotion"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="showAdvancedSettings">Advanced</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                </div>
                                {this.state.advanced &&
                                    <div className="form-group">
                                        <label htmlFor="squareInfoText">Add A Clue Or Info About Square:</label>
                                        <RichTextEditor id="squareInfoText" name="squareInfoText" toolbarConfig={toolbarConfig} value={this.state.squareInfoText} onChange={(text)=>this.handleRichTextChange("squareInfoText", text)}/>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="squareItemImage" style={{width:'100%'}}>Square Item Image</label>
                                    <img src={this.state.editSquareItemImage} width="auto" height="100px" style={{display: this.state.editingItem ? '' : 'none' }} alt=""/>
                                    <Dropzone
                                        className="dropzone dz-clickable"
                                        accept="image/*"
                                        onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'editSquareItemImage')}}
                                        multiple={false}
                                        maxSize={2200000}>
                                        <div className="dz-message needsclick">
                                            <span className="fa fa-cloud-upload text-muted" style={{display: editSquareItemImagePreview.preview ? 'none' : ''}}/>
                                            <h3 style={{display: editSquareItemImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                            <img
                                                style={{display: editSquareItemImagePreview.preview ? '' : 'none'}}
                                                src={editSquareItemImagePreview.preview}
                                                width="100px"
                                                height="auto"
                                                alt="Drop your image here"
                                            />
                                        </div>
                                    </Dropzone>
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn btn-primary btn-lg submit-button" id="submitButton"
                                            onClick={() => this.createSquareItem()}>Submit Square Item
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SetUpSquareItem
